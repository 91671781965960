<footer class="footer">
	<div class="container">
		<div class="footer-content text-center">
			<div class="contact-block mr-auto ml-auto text-center">
				<div class="row">
					<div class="item col-12 col-lg-4">
						<div class="item-inner">
							<div class="icon-holder">
								<i class="fas fa-info-circle"></i>
							</div>
							<!--//icon-holder-->
							<h4 class="title">Allgemeine Anfragen</h4>
							<div class="email"><a href="mailto:info@petruschka.ch">info&#64;petruschka.ch</a></div>
						</div>
						<!--//item-inner-->
					</div>
					<!--//item-->

					<div class="item col-12 col-lg-4">
						<div class="item-inner">
							<div class="icon-holder">
								<i class="fas fa-calendar-check"></i>
							</div>
							<!--//icon-holder-->
							<h4 class="title">Newsletter</h4>

							<div class="email">
								<a (click)="openNewsletterForm()" class="location-link"
								><i class="fas fa-external-link-square-alt"></i> Newsletter </a
							  ></div>
						</div>
						<!--//item-inner-->
					</div>
					<!--//item-->

					<div class="item col-12 col-lg-4">
						<div class="item-inner">
							<div class="icon-holder">
								<i class="fas fa-newspaper"></i>
							</div>
							<!--//icon-holder-->
							<h4 class="title">Booking / Presse</h4>
							<div class="email"><a href="mailto:mariannehofer@kinderkultur.ch">mariannehofer&#64;kinderkultur.ch</a></div>
						</div>
						<!--//item-inner-->
					</div>
					<!--//item-->
				</div>
				<!--//row-->
			</div>
			<div class="channels-block">
				<ul class="channels-list list-inline">
					<!-- <li class="list-inline-item mr-3"><a href="#"><img alt="" class="icon" src="assets/images/itunes.svg"></a></li>
						<li class="list-inline-item mr-3"><a href="#"><img alt="" class="icon" src="assets/images/lastfm.svg"></a></li>
						<li class="list-inline-item mr-0 mr-md-3"><a href="#"><img alt="" class="icon" src="assets/images/vevo.svg"></a>
						</li>
						<li class="d-block d-md-none"></li>
						<li class="list-inline-item mr-3"><a href="#"><img alt="" class="icon" src="assets/images/bandcamp.svg"></a>
						</li>
						<li class="list-inline-item mr-0"><a href="#"><img alt="" class="icon" src="assets/images/deezer.svg"></a></li> -->
				</ul>
			</div>
			<div class="social-media-block">
				<h4 class="title">Partner</h4>
				<ul class="list-inline social-media-list">
					<!-- <li class="list-inline-item mr-3"><a href="#"><i class="fab fa-youtube"></i></a></li> -->
					<!-- <li class="list-inline-item mr-3"><a href="#"><i class="fab fa-soundcloud"></i></a></li>
						<li class="list-inline-item mr-0 mr-sm-3"><a href="#"><i class="fab fa-spotify"></i></a></li> -->
					<li class="d-block d-sm-none"></li>
					<li class="list-inline-item mr-3">
						<a href="https://schlosswochen.ch"><i class="fab fa-fort-awesome-alt"></i></a>
						<a href="https://schlosswochen.ch">Schlosswochen</a>
					</li>
					<!-- <li class="list-inline-item mr-3"><a href="#"><i class="fab fa-instagram"></i></a></li> -->
					<li class="list-inline-item mr-0">
						<a href="https://kindundnatur.ch"><i class="fas fa-leaf"></i></a>
						<a href="https://kindundnatur.ch">Kind und Natur</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>
